import React from 'react'
import * as Sentry from '@sentry/react';
import {GoogleLogin} from '@react-oauth/google';
import auth from './Account/Auth';

function Fallback(){return <div>an error occurred</div>}
const fallback = <Fallback/>;

function Login ({redirect, callback, setLoggingProvider}){
  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <GoogleLogin
        width={350}
        click_listener={() => {setLoggingProvider && setLoggingProvider("google")}}
        onSuccess={async (credentialResponse) => {
          try{
            await auth.login({idToken:credentialResponse?.credential})
              .then(() => {
                // check on '/onboarding' pathname helps avoiding redirect override
                // in case auth module needs to bring the user on onboarding page
                if (redirect && window.location.pathname !== '/onboarding') { window.location.href = redirect }
                if (callback) { callback() }
              })
              .catch(e => {
                console.log("request catch: error while logging in with google provider: ", e)
              })
          }catch(e){
            console.log("functional catch: error while logging in with google provider: ", e)
          }finally{
            (setLoggingProvider && setLoggingProvider("none"))
          }
        }}
        onError={() => {
          console.log('Login Failed');
          (setLoggingProvider && setLoggingProvider("none"))
        }}
        useOneTap
      />
    </Sentry.ErrorBoundary>
  );
}

export default Login
