export default {
  API: {
    endpoints: [
        {
          name: "account-prod",
          endpoint: "https://tpfgqh9ei1.execute-api.eu-central-1.amazonaws.com/staging",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "eventtypes-prod",
          endpoint: "https://x8dwtuq3k6.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "booking-prod",
          endpoint : "https://3udcgulto7.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "jubilant-booking",
          endpoint : "https://raqt4k4huc.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name : "payments-prod",
          endpoint : "https://easy3n59vl.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "notifications-prod",
          endpoint: "https://2cbrq1suy1.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "todo-prod",
          endpoint: "https://77no9um4sf.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "stacking-prod",
          endpoint: "https://67yn0ewloa.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "calendarhandler-prod",
          endpoint: "https://vdj4u27hz4.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "webhook-prod",
          endpoint: "https://un0o0safi8.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "survey-prod",
          endpoint: "https://ibj88khshg.execute-api.eu-central-1.amazonaws.com/prod",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "integrations-prod",
          endpoint: "https://integrations.calendbook.com",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "stripe-connect",
          endpoint: "https://3dop58y0if.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "account-jubilant",
          endpoint: "https://dk4uaonnu0.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "uploader-stack",
          endpoint: "https://9gnlf4d89b.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "team-manager",
          endpoint: "https://ymxafp6wr3.execute-api.eu-central-1.amazonaws.com/Stage",
          region: 'ap-southeast-2',
          service: 'lambda'
        },
        {
          name: "payment-jubilant",
          endpoint: "https://03mk8bflne.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "agency",
          endpoint: "https://c5cq7fl8xf.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "jubilant-events",
          endpoint: "https://mbi8mft72c.execute-api.eu-central-1.amazonaws.com/Stage",
        },
        {
          name: "Onboarding",
          endpoint: "https://4sazvyztf1.execute-api.eu-central-1.amazonaws.com/Stage",
        },
    ]
  }
};
